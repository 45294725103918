import { useState, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
//Foundation libraries

import Axios, { Canceler } from "axios";
import { useSelector } from "react-redux";
//Custom libraries
import * as ROUTES from "../../constants/routes";
import {
  forUserIdSelector,
  userIdSelector,
  employeeAttributteSelector,
  loginStatusSelector,
} from "../../redux/selectors/user";
import {
  isStandingOrderSelector,
  isEmployeePurchaseSelector,
  acceleratePgmSelector,
  activeOrgSelector,
} from "../../redux/selectors/organization";
import personService from "../apis/transaction/person.service";
import {
  IBM_ASSIGNED_ROLE_DETAILS,
  BUYER_ADMIN_ROLE,
  BUYER_APPROVAL_ROLE,
  CUSTOMER_SERVICE_REP_ROLE,
} from "../../constants/common";
import {
  BookmarkBorder,
  FastForward,
  History,
  LibraryBooks,
  PeopleAlt,
  PersonAdd,
  Repeat,
  List,
} from "@material-ui/icons";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { EMPTY_STRING } from "../../constants/common";
import chcOrganizationService from "../../_foundation/apis/transaction/chc-organization.service";
//UI

export const useDashboardSections = () => {
  const { t } = useTranslation();
  const CancelToken = Axios.CancelToken;
  const cancels: Canceler[] = [];

  const userId = useSelector(userIdSelector);
  const forUserId = useSelector(forUserIdSelector);
  const standingOrder = useSelector(isStandingOrderSelector);
  const employeePurchase = useSelector(isEmployeePurchaseSelector);
  const employeeAttributte = useSelector(employeeAttributteSelector);
  const acceleratePgm = useSelector(acceleratePgmSelector);
  const activeOrgId = useSelector(activeOrgSelector);
  const loginStatus = useSelector(loginStatusSelector);
  const [reportViewer, setReportViewer] = useState<boolean>(false);

  const payloadBase: any = {
    widget: "section-array",
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c);
    }),
  };

  const [buyerRole, setBuyerRole] = useState<string[]>([]);
  const isBuyerApproved = useMemo(
    () =>
      !buyerRole?.includes(BUYER_ADMIN_ROLE) &&
      buyerRole?.includes(BUYER_APPROVAL_ROLE),
    [buyerRole]
  );

  const isBuyerApprover = useMemo(
    () => buyerRole?.includes(BUYER_APPROVAL_ROLE),
    [buyerRole]
  );

  const isEmployeePurchase = useMemo(
    () => employeePurchase && employeeAttributte !== EMPTY_STRING,
    [employeePurchase, employeeAttributte]
  );

  const getPerson = () => {
    const param = {
      userId: forUserId ?? userId,
      profileName: IBM_ASSIGNED_ROLE_DETAILS,
      ...payloadBase,
    };
    personService
      .findByUserId(param)
      .then((response) => response.data)
      .then((data) => {
        const roleDetail = data?.rolesWithDetails;
        if (roleDetail) {
          checkBuyerAdmin(roleDetail);
        }
      })
      .catch((e) => {
        console.log("Could not retrieve role details");
      });
  };

  const checkBuyerAdmin = (roleDetail) => {
    const roles: string[] = [];
    for (const value of roleDetail) {
      roles.push(String(value.roleId));
    }
    setBuyerRole(roles);
  };

  useEffect(() => {
    if (userId || forUserId) {
      getPerson();
    }
    if(activeOrgId && loginStatus){
      getReportViewer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, forUserId,activeOrgId]);

  useEffect(() => {
    return () => {
      cancels.forEach((cancel) => cancel());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

const getReportViewer = () =>{
  const responseFormat = "application/json";
  
      chcOrganizationService.getActiveOrgReportViewerRole({activeOrgId,responseFormat}).then((response) => {
        
        if(response.data.Role === 1){
          setReportViewer(true);
       
        }
        else setReportViewer(false);
      });
    
}

  const accountSetting_B2B = useMemo(
    () => ({
      title: t("AccountLinks.Title"),
      pages: [
        {
          title: t("AccountLinks.AddressBookText"),
          description: t("AccountLinks.AddressBookDescription"),
          link: ROUTES.ADDRESS_BOOK,
          icon: <LibraryBooks />,
        },
        {
          title: t("OrderLinks.RequisitionListsText"),
          description: t("OrderLinks.RequisitionListsDescription"),
          link: ROUTES.REQUISITION_LISTS,
          icon: <List />,
        },
        ...(!reportViewer 
          ? [
        {
          title: t("AccountLinks.MyCard"),
          description: t("AccountLinks.MyCardDesc"),
          link: ROUTES.MY_CARD,
          icon: <CreditCardIcon />,
        }]:[]),
        {
          title: t("OrderLinks.QuickOrderText"),
          description: t("OrderLinks.QuickOrderDescription"),
          link: ROUTES.CART,
          icon: <BookmarkBorder />,
        },

        ...(standingOrder === "True" && !isEmployeePurchase
          ? [
              {
                title: t("OrderLinks.RecurringOrdersText"),
                description: t("OrderLinks.RecurringOrdersDescription"),
                link: ROUTES.RECURRING_ORDERS,
                icon: <Repeat />,
              },
            ]
          : []),
      ],
    }),
    [t, standingOrder, isEmployeePurchase,reportViewer]
  );

  const orderManagement = useMemo(
    () => ({
      title: t("OrderLinks.Title"),
      pages: [
        {
          title: t("OrderLinks.OrderHistoryText"),
          description: t("OrderLinks.OrderHistoryDescription"),
          link: ROUTES.ORDER_HISTORY,
          icon: <History />,
        },
        ...(!isEmployeePurchase
          ? [
              {
                title: t("OrderLinks.BackOrderText"),
                description: t("OrderLinks.BackOrderDescription"),
                link: ROUTES.BACK_ORDERS_REPORT,
                icon: <History />,
              },
            ]
          : []),
        ...(!isEmployeePurchase
          ? [
              {
                title: t("Invoices.InvoicesText"),
                description: t("Invoices.InvoicesDescription"),
                link: ROUTES.INVOICES_IBILLDER,
                icon: <List />,
              },
            ]
          : []),
        ...(isBuyerApproved
          ? [
              {
                title: t("OrderLinks.ApproveOrdersText"),
                description: t("OrderLinks.ApproveOrdersDescription"),
                link: ROUTES.ORDER_APPROVAL,
                icon: <PersonAdd />,
              },
            ]
          : []),
      ],
    }),
    [t, isBuyerApproved, isEmployeePurchase]
  );

  const B2B_Admin = useMemo(
    () => ({
      title: t("AdminTools.adminTools"),
      pages: [
        {
          title: t("AdminTools.buyerManagement"),
          description: t("AdminTools.buyerManagementDesc"),
          link: ROUTES.BUYER_MANAGEMENT,
          icon: <PeopleAlt />,
        },
        ...(isBuyerApprover
          ? [
              {
                title: t("AdminTools.orgAndBuyer"),
                description: t("AdminTools.orgAndBuyerDesc"),
                link: ROUTES.APPROVALS_MANAGEMENT,
                icon: <PersonAdd />,
              },
            ]
          : []),
      ],
    }),
    [t, isBuyerApprover]
  );

  const dashboardSections = useMemo(() => {
    return [
      accountSetting_B2B,
      orderManagement,
      ...(buyerRole.includes(BUYER_ADMIN_ROLE) ? [B2B_Admin] : []),
    ];
  }, [buyerRole, accountSetting_B2B, orderManagement, B2B_Admin]);

  return {
    dashboardSections,
    isCSRRole: buyerRole.includes(CUSTOMER_SERVICE_REP_ROLE),
  };
};
