import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import * as ROUTES from "../../../../constants/routes";


const AccelerateProgramPage: FC = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    // Redirect to the dashboard
    navigate(ROUTES.DASHBOARD);
  }, [navigate]);

  return null; // Return null since the page will redirect
};

export default AccelerateProgramPage;