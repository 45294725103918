/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */

export const HOME = "/";

//Order
export const CART = "/cart";
export const CHECKOUT = "/checkout";
export const CHECKOUT_SHIPPING = `shipping`;
export const CHECKOUT_PAYMENT = `payment`;
export const CHECKOUT_REVIEW = `review`;
export const CHECKOUT_PICKUP = `pickup`;
export const CHECKOUT_PICKUP_STORE = `pickup-store`;
export const ORDER_CONFIRMATION = "/order-confirmation";
export const ORDER_HISTORY = "/order-history";
export const WISH_LIST = "/wish-list";
export const WISH_LIST_ID = "/:wishListId";
export const VIEW_WISH_LIST_ROUTE = `${WISH_LIST}${WISH_LIST_ID}`;
export const RECURRING_ORDERS = "/recurring-orders";
export const INPROGRESS_ORDERS = "/inprogress-orders";
export const REQUISITION_LISTS = "/saved-lists";
export const VIEW_UPLOAD_LOGS = "/view-upload-logs";
export const ORDER_DETAILS = "/order-details";
export const ORDER_ID_PARAM = "/:orderId";
export const ORDER_DETAILS_ROUTE = `${ORDER_DETAILS}${ORDER_ID_PARAM}`;
export const LIST_ID_PARAM = "/:orderId";
export const BACK_ORDERS_REPORT = "/back-order-report";
export const SURGERY_CENTER = "/surgery-center";
export const HOSPITALS = "/hospitals";
export const VETERINARY = "/veterinary-clinics";
export const LTC = "/long-term-care";
export const DEALER = "/distributors-retailers";
export const DENTISTS = "/dentists";
export const PHYSICIANS = "/physicians-clinics";
export const DEFAULT = "/acute";


// recurring orders details
export const RECURRING_ORDER_DETAILS = "/recurring-order-details";
export const RECURRING_ID_PARAM = "/:orderId";
export const RECURRING_DETAILS_ROUTE = `${RECURRING_ORDER_DETAILS}${RECURRING_ID_PARAM}`;

//User
export const ADDRESS_BOOK = "/select-account";
export const ADD_ADDRESS = "/add-address";
export const EDIT_ADDRESS = "/edit-address";
export const ADDRESS_ID_PARAM = "/:addressId";
export const EDIT_ADDRESS_ROUTE = `${EDIT_ADDRESS}${ADDRESS_ID_PARAM}`;
export const NEW_RESIDENT = "/new-resident";
export const NEW_SHIP_TO_ACCOUNT = "/new-ship-to-account";

//Payment Method
export const MY_CARD = "/my-card";

//Account
export const SIGNIN = "/sign-in";
export const BUYER_REGISTRATION = "/buyer-registration";
export const ORG_REGISTRATION = "/organization-registration";
export const REF_REGISTRATION = "/registerreferralcode";
export const TERMS_CONDITIONS = "/terms-conditions";
export const ACCOUNT = "/account";
export const DASHBOARD = "/dashboard";
export const FORGOT_PASSWORD = "/forgot-password";
export const ACCELERATE_PROGRAM = "/accelerate-program";
export const INVOICES_IBILLDER = "/invoices";
export const SELECTACCOUNT = "/select-account";
export const NOTIFICATIONS = "/notifications";

//Search
export const SEARCH = "/search";

//register user access only
export const REGISTER_PROTECTED = "register";
//only guest and generic user access
export const GENERIC_PROTECTED = "generic";
export const ADMIN_PROTECTED = "admin";
export const MARKETING_PROTECTED = "marketing";
export const LANDING_PAGE = "/landing-:pageId";

export const BUYER_MANAGEMENT = "/user-management";
export const APPROVALS_MANAGEMENT = "/approvals-management";
export const ORGANIZATION_MANAGEMENT = "/organization-management";
export const ORDER_APPROVAL = "/order-approval";

//Checkout Profile
export const CHECKOUT_PROFILES = "/list-checkout-profiles";
export const CHECKOUT_PROFILE_CREATE = "/checkout-profile-create";
export const CHECKOUT_PROFILE_EDIT = "/edit-checkout-profile";

// in-progress orders + items
export const IP_ORDER_DETAILS = "/inprogress-order-details";
export const IP_ORDER_DETAILS_ROUTE = `${IP_ORDER_DETAILS}${ORDER_ID_PARAM}`;

// Reqisition List + items
export const REQUISITION_LIST_DETAILS = "/saved-list-details";
export const REQUISITION_LIST_DETAILS2 = "/saved-list-details2";
export const REQUISITION_LIST_DETAILS_ROUTE = `${REQUISITION_LIST_DETAILS}${LIST_ID_PARAM}`;
export const REQUISITION_LIST_DETAILS_ROUTE2 = `${REQUISITION_LIST_DETAILS2}${LIST_ID_PARAM}`;

export const COMPARE_ROUTE = "/compare-products";

export const STORE_LOCATOR = "/store-locator";
export const DELIVERY_STEPS = ["shipping", "payment", "review"];
export const PICKUP_STEPS = ["pickup-store", "pickup", "payment", "review"];
