import { useState, useEffect, useRef } from "react";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { styled } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import {
  StyledButton,
  StyledTypography
} from "@hcl-commerce-store-sdk/react-component";
import { UnfoldMore } from "@material-ui/icons";
import StaticMenu from "./StaticMenu";
import Modal from "@material-ui/core/Modal";


const StyledPaper = styled(Paper)({
  position: 'absolute',
  width: 300,
  height: 'calc(100vh - 54px)', // Adjust height to fit within the viewport
  overflowY: 'auto',
  zIndex: 1001,
  opacity: 1,
  transform: 'translateY(0)',
  transition: 'opacity 0.3s ease, transform 0.3s ease',
});

const ModalContainer = styled('div')({
  position: 'relative',
  height: '100%'
});

const ExpandButton = styled(StyledButton)(({ theme }) => ({
  position: "relative",
  minWidth: "210px",
  "& .MuiButton-label": {
    justifyContent: "flex-start",
  },
  "& .MuiTypography-root": {
    paddingRight: "1rem",
    textAlign: "left",
  },
  "& svg": {
    position: "absolute",
    right: 0,
    top: "50%",
    transform: "translateY(-50%)",
  },
}));

const DesktopMenuV2 = (props) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState(null);
  const [openSubSubMenu, setOpenSubSubMenu] = useState(null);
  const [dataReady, setDataReady] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const navigate = useNavigate();

  const menuData = props.page.children;
  const page = props.page;
  const selectedMenuItem = props.selectedMenuItem;

  useEffect(() => {
    if (menuData && menuData.length > 0) {
      setDataReady(true);
    }
  }, [menuData]);

  const handleCategoryClick = (category) => {
    if (!category.children || category.children.length === 0) {
      navigate(category.seo.href);
      handleClickAway();
    }
  };

  const handleSubMenuClick = (index) => {
    setOpenSubMenu(index);
    setOpenSubSubMenu(null); // Close sub-sub-menu when a new sub-menu is opened
  };

  const handleSubSubMenuClick = (index) => {
    setOpenSubSubMenu(index);
  };

  const handleClickAway = () => {
    setOpenMenu(false);
    setOpenSubMenu(null);
    setOpenSubSubMenu(null);
  };

  if (!dataReady) {
    return null; // Render nothing if data is not ready
  }

  const buttonHeight = buttonRef.current ? buttonRef.current.offsetHeight : 0;
  const buttonTop = buttonRef.current ? buttonRef.current.getBoundingClientRect().top : 0;
  const buttonLeft = buttonRef.current ? buttonRef.current.getBoundingClientRect().left : 0;

  const getModalWidth = () => {
    if (openSubMenu !== null && openSubSubMenu !== null) {
      return 900;
    } else if (openSubMenu !== null) {
      return 600;
    } else {
      return 300;
    }
  }
  return (
    
      <div className="desktop-menu">
        <ExpandButton
          testId={`header-desktop-menu`}
          variant="contained"
          size="small"
          color="primary"
          onClick={() => setOpenMenu(!openMenu)}
          className={selectedMenuItem?.id === page.id ? "selected" : ""}
          ref={buttonRef}
          style={{ zIndex: 10000 }}
        >
          <StyledTypography>
            <strong>{page.name}</strong>
          </StyledTypography>
          <UnfoldMore />
        </ExpandButton>
        <Modal open={openMenu} onClose={handleClickAway}>
        <ModalContainer style={{ top: buttonTop/2 + buttonHeight, left: buttonLeft, position: 'absolute', width: getModalWidth() }}>
          <>
        {openMenu && (
          <StyledPaper
            elevation={3}
            className={`menu-level-1 ${openMenu ? "open" : ""}`}
            style={{ left: "0px", top: buttonHeight, marginBottom: '20px' }}
          >
            <List>
              {menuData.map((item, index) => (
                <ListItem
                  button
                  key={index}
                  className="menu-item-desktop"
                  onClick={() => {
                    if (item.children && item.children.length > 0) {
                      handleSubMenuClick(index);
                    } else {
                      handleCategoryClick(item);
                    }
                  }}
                >
                  <ListItemText primary={item.name} />
                  {item.children && (
                    <ListItemIcon>
                      <KeyboardArrowRightIcon />
                    </ListItemIcon>
                  )}
                </ListItem>
              ))}
             <div className='staticMenuDesktop'>
                <StaticMenu handleCategoryClickClose={handleClickAway} />
              </div>
            </List>
          </StyledPaper>
        )}

        {openSubMenu !== null && (
          <StyledPaper
            elevation={3}
            className={`menu-level-2 ${openSubMenu !== null ? "open" : ""}`}
            style={{ left: "300px", top: buttonHeight, marginBottom: '20px' }}
          >
            <List>
              {menuData[openSubMenu].children.map((subItem, subIndex) => (
                <ListItem
                  button
                  key={subIndex}
                  className="menu-item-desktop"
                  onClick={() => {
                    if (subItem.children && subItem.children.length > 0) {
                      handleSubSubMenuClick(subIndex);
                    } else {
                      handleCategoryClick(subItem);
                    }
                  }}
                >
                  <ListItemText primary={subItem.name} />
                  {subItem.children && (
                    <ListItemIcon>
                      <KeyboardArrowRightIcon />
                    </ListItemIcon>
                  )}
                </ListItem>
              ))}
            </List>
          </StyledPaper>
        )}

        {openSubMenu !== null && openSubSubMenu !== null && (
          <StyledPaper
            elevation={3}
            className={`menu-level-3 ${openSubSubMenu !== null ? "open" : ""}`}
            style={{ left: "600px", top: buttonHeight, marginBottom: '20px' }}
          >
            <List>
              {menuData[openSubMenu].children[openSubSubMenu].children.map(
                (subSubItem, subSubIndex) => (
                  <ListItem
                    button
                    key={subSubIndex}
                    onClick={() => handleCategoryClick(subSubItem)}
                    className="menu-item-desktop"
                  >
                    <ListItemText primary={subSubItem.name} />
                  </ListItem>
                )
              )}
            </List>
          </StyledPaper>
        )}
        </>
        </ModalContainer>
        </Modal>
      </div>
    
  );
};

export default DesktopMenuV2;
