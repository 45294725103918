/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React, { useEffect, useMemo } from "react";
import { matchRoutes, useLocation, useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
//Foundation libraries
import { useSite } from "../../_foundation/hooks/useSite";
//Custom libraries
import { ROUTE_CONFIG } from "../../configs/routes";
import { REGISTER_PROTECTED, ADMIN_PROTECTED, GENERIC_PROTECTED, HOME, DASHBOARD, MARKETING_PROTECTED } from "../../constants/routes";
//Redux
import { loginStatusSelector, userInitStatusSelector, userAdminSelector } from "../../redux/selectors/user";
import { activeSegment } from "../../redux/selectors/organization";
import { seoSelector } from "../../redux/selectors/seo";
import { CHILD_ROUTE_SEPARATOR } from "../constants/common";
import { GET_SEO_CONFIG_ACTION } from "../../redux/actions/seo";
import Axios, { Canceler } from "axios";
import getDisplayName from "react-display-name";


const LoginGuard: React.FC = () => {
  const { mySite } = useSite();
  const location: any = useLocation();
  const navigate = useNavigate();
  const loginStatus = useSelector(loginStatusSelector);
  const userInited = useSelector(userInitStatusSelector);
  const isAdmin = useSelector(userAdminSelector);
  const seoConfig = useSelector(seoSelector);
  const { pathname } = location;
  const activeSegmentValue = useSelector(activeSegment);
  const dispatch = useDispatch();

  const widgetName = getDisplayName(LoginGuard);

  const identifier = useMemo(() => {
      const _i = pathname.substring(1) || HOME;
      //Rest client is using URLSearchParams to encode the query parameters.
      return decodeURI(_i.split(CHILD_ROUTE_SEPARATOR)[0]);
    }, [pathname]);
  const cancels: Canceler[] = [];

  const CancelToken = Axios.CancelToken;
  const payloadBase: any = {
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c);
    }),
  };

    React.useEffect(() => {
      if (mySite !== null && identifier) {
        dispatch(
          GET_SEO_CONFIG_ACTION({
            identifier,
            ...payloadBase,
          })
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mySite, identifier]);
  
  useEffect(() => {
    if (mySite && userInited) {
      const routes = mySite.isB2B ? ROUTE_CONFIG.B2B : ROUTE_CONFIG.B2C;
      const branch = matchRoutes(routes, location.pathname);
      let protectedRoute;
      let protectedAdminRoute;
      let protectedMarketingRoute;
      
      if (!loginStatus) {
        protectedRoute = branch?.some((b) => {
          return b.route["isProtected"] === REGISTER_PROTECTED;
        });
        protectedAdminRoute = branch?.some((b) => {
          return b.route["isProtected"] === ADMIN_PROTECTED;
        });
        protectedMarketingRoute = branch?.some((b) => {
          return b.route["isProtected"] === MARKETING_PROTECTED;
        });
        if (protectedRoute || protectedAdminRoute || protectedMarketingRoute) {
          navigate(HOME);
        }
      } else {
        protectedRoute = branch?.some((b) => {
          return b.route["isProtected"] === GENERIC_PROTECTED;
        });
        protectedAdminRoute = branch?.some((b) => {
          return b.route["isProtected"] === ADMIN_PROTECTED;
        });
        protectedMarketingRoute = branch?.some((b) => {
          return b.route["isProtected"] === MARKETING_PROTECTED;
        });
        if (protectedRoute) {
          navigate(DASHBOARD);
        }
        if (protectedAdminRoute && !isAdmin) {
          navigate(DASHBOARD);
        }
        if (protectedMarketingRoute && seoConfig && seoConfig[identifier] && activeSegmentValue) {
          if(seoConfig[identifier].page?.metaKeyword) {
            if(activeSegmentValue !== seoConfig[identifier].page?.metaKeyword){
              navigate(HOME);
            }
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, loginStatus, mySite, userInited, seoConfig, activeSegmentValue]);

  return <></>;
};

export default LoginGuard;
