/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020,2021
 *
 *==================================================
 */
//custom
//import DesktopMenu from "./DesktopMenu";
import DesktopMenu from "./DesktopMenuV2";

const MenubarMenuItem = (props) => {
  const { page, selectedMenuItem } = props;
  
  return (
    <>
    <DesktopMenu page = {page} selectedMenuItem = {selectedMenuItem}/>
    </>
  );
};

export default MenubarMenuItem;
